import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import tw from "twin.macro"
import { useLocation } from "@reach/router"
import {
  H5,
  Body1,
  H3,
  Container,
  PrimaryContent,
  Caption,
} from "../components"

const HeaderText = () => {
  const [isPreviewSitemap, setIsPreviewSitemap] = useState(false)
  const { hostname } = useLocation()

  useEffect(() => {
    setIsPreviewSitemap(
      hostname?.startsWith("deploy-preview") || hostname === "localhost"
    )
  }, [hostname])

  return isPreviewSitemap ? (
    <>
      <H3 className="font-serif text-av-purple mb-0">Preview Sitemap</H3>
      <Caption className="mb-6 italic">
        You are viewing the preview sitemap, select the path of the content
        being edited in the CMS to view the unpublished preview.
      </Caption>
    </>
  ) : (
    <H3 className="font-serif mb-6">Aventine Sitemap</H3>
  )
}

const getHomePageNode = nodes => {
  const homepageNodes = nodes.filter(
    ({ node }) => node.frontmatter.slug === "/"
  )
  if (homepageNodes.length === 1) {
    return homepageNodes[0].node
  }
  return homepageNodes.filter(
    ({ node }) => node.frontmatter.templateKey !== "coming-soon"
  )[0].node
}

const PageEntryTemplate = ({ name, slug }) => {
  return (
    <div className="flex py-4 px-2 border-b border-black">
      <H5>{name}</H5>
      <Body1 as={Link} to={slug} className="ml-auto">
        {slug}
      </Body1>
    </div>
  )
}

export default function Sitemap() {
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
      allMdx(filter: { frontmatter: { slug: { ne: null } } }) {
        edges {
          node {
            id
            frontmatter {
              slug
              templateKey
              name
            }
          }
        }
      }
    }
  `)
  const homepageEntry = getHomePageNode(data.allMdx.edges)

  return (
    <Container tw="lg:block mt-10">
      <PrimaryContent>
        <HeaderText />
        {data.allSitePage.nodes
          .filter(
            page =>
              page.context?.frontmatter &&
              page.context?.frontmatter?.templateKey
          )
          .map(page => (
            <PageEntryTemplate
              key={page.path}
              name={page.context.frontmatter?.templateKey}
              slug={page.path}
            />
          ))}
        {/* Pages created directly through onCreatePage */}
        {data.allMdx.edges
          .filter(({ node }) => node.frontmatter.slug !== "/")
          .map(
            ({
              node: { id, frontmatter: { name, slug, templateKey } = {} },
            }) => {
              return (
                <PageEntryTemplate
                  key={id}
                  name={`${name} (${templateKey})`}
                  slug={slug}
                />
              )
            }
          )}
        <PageEntryTemplate
          name={`${homepageEntry.frontmatter.name} (${homepageEntry.frontmatter.templateKey})`}
          slug={homepageEntry.frontmatter.slug}
        />
      </PrimaryContent>
    </Container>
  )
}
